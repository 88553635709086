import { useCallback } from 'react'
import { VariantType, useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import i18n from '../utils/i18n.utils'
import { logIfDev } from '../utils/commons.utils'

const errorTexts: { [key: string]: string } = {
  UNAUTHORIZED: 'errors:unauthorized',
  USER_ALREADY_REGISTERED: 'errors:emailAlreadyExists',
  WRONG_CREDENTIALS: 'errors:wrongCredentials',
  ACCOUNT_NOT_CONFIRMED: 'errors:accountNotConfirmed',
  USER_NOT_FOUND: 'errors:userNotFound',
  ORGANIZATION_NOT_FOUND: 'errors:organizationNotFound',
  FILE_NOT_FOUND: 'errors:fileNotFound',
  CATALOG_NOT_FOUND: 'errors:catalogNotFound',
  CATEGORY_NOT_FOUND: 'errors:categoryNotFound',
  ORDER_NOT_FOUND: 'errors:orderNotFound',
  NOT_CONNECTED: 'errors:notConnected',
  NEED_SYNC: 'errors:needSync',
  CONTACT_US: 'errors:contactUs',
  INVALID_ORDER_STATUS: 'errors:invalidOrderStatus',
  MATERIAL_NOT_FOUND: 'errors:materialNotFound',
  INVALID_CATALOG_STATUS: 'errors:invalidCatalogStatus',
  ORGANIZATION_ALREADY_EXISTS: 'errors:organizationAlreadyExists',
  INVALID_USER_STATUS: 'errors:invalidUserStatus',
  DELETE_LOCATED_QUANTITY: 'errors:deleteLocatedErrorQuantity',
  MISSING_QUANTITIES: 'errors:missingQuantities',
  MISSING_QUANTITY: 'errors:missingQuantity',
  NEW_VERSION_MANDATORY: 'errors:newVersionMandatory',
  INVALID_VISIBLE_NO_USERS: 'errors:visibleNoUsers',
  INVALID_DECONSTRUCTION_DATE: 'errors:deconstructionDate',
  REPORT_NAME_ALREADY_EXISTS: 'errors:reportNameAlreadyExists',
  WASTE_ONLY_TRANSFER_INVALID: 'errors:wasteOnlyTransferInvalid',
}
type Show = (error: Error | AxiosError | string, variant?: VariantType) => void
const useOwnSnackbar = (): Show => {
  const { enqueueSnackbar } = useSnackbar()
  const show = useCallback(
    (error: Error | AxiosError | string, variant?: VariantType): void => {
      let message: string =
        typeof error === 'string'
          ? error
          : ((error as AxiosError).response?.data as any)?.error || error.message

      let data = {}
      if (((error as AxiosError).response?.data as any)?.message) {
        let responseData = ((error as AxiosError).response?.data as any)?.message
        if (typeof responseData === 'object') {
          data = responseData
        }
      }
      let computedVariant =
        variant ||
        (message === 'ACCOUNT_NOT_CONFIRMED'
          ? 'warning'
          : typeof error === 'string'
          ? 'success'
          : 'error')

      let text
      if (typeof error !== 'string') {
        logIfDev(error)
        text = errorTexts[message]
          ? i18n.t(errorTexts[message] as any, { data })
          : i18n.t('errors:global')
      } else {
        text = i18n.t(message as any)
      }

      enqueueSnackbar(text, { variant: computedVariant })
    },
    [enqueueSnackbar],
  )
  return show
}

export default useOwnSnackbar
