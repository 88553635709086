import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export namespace ObjectUtils {
  export function copy(base: any) {
    const res: any = {}

    Object.keys(base).forEach((key: string) => {
      if (base[key] && typeof base[key] === 'object') {
        if (base[key].length >= 0 && !!base[key].slice) {
          res[key] = []
          for (let i = 0; i < base[key].length; i++) {
            if (base[key][i] && typeof base[key][i] === 'object') {
              res[key].push(ObjectUtils.copy(base[key][i]))
            } else {
              res[key].push(base[key][i])
            }
          }
        } else {
          res[key] = ObjectUtils.copy(base[key])
        }
      } else {
        res[key] = base[key]
      }
    })
    return res
  }
  export function merge(base: any, override: any) {
    if (!base) {
      return override
    }
    if (!override) {
      return base
    }
    const res: any = {}
    Object.keys(base).forEach((key: string) => {
      if (typeof base[key] === 'object') {
        res[key] = ObjectUtils.merge(base[key], override[key])
      } else {
        res[key] = override[key] !== undefined ? override[key] : base[key]
      }
    })

    Object.keys(override).forEach((key: string) => {
      if (!base[key]) {
        res[key] = override[key]
      }
    })
    return res
  }
  export function getKeyValue(base: Record<string, any>, key: string): any {
    let splittedKey = key.split(/\./)
    let current = base
    for (let i = 0; i < splittedKey.length; i++) {
      current = current?.[splittedKey[i]]
    }
    return current
  }
  export function setKeyValue(base: Record<string, any>, key: string, value: any): any {
    let splittedKey = key.split(/\./)
    let copy = { ...base }
    let current = copy
    for (let i = 0; i < splittedKey.length; i++) {
      if (i < splittedKey.length - 1) {
        if (!current?.[splittedKey[i]]) {
          current[splittedKey[i]] = {}
        }
        current = current?.[splittedKey[i]]
      } else {
        current[splittedKey[i]] = value
      }
    }

    return copy
  }
}

export namespace StringUtils {
  export const removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  export const normalizeIncludes = (str: string, search: string) => {
    return removeAccents(str.toLowerCase()).includes(removeAccents(search.toLowerCase()))
  }

  export const formatNumber = (number: string | number = ''): string => {
    let value = parseFloat((number + '').replace(/\s*/g, ''))
    const hasDecimal = (number + '').includes('.') || (number + '').includes(',')
    let split = value.toString().split(/\.|,/g)
    if (!isNaN(value)) {
      return (
        split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
        (hasDecimal ? '.' : '') +
        (split[1]?.substring(0, 2) || '')
        // (split[1]
        //   ?.split('')
        //   .reverse()
        //   .join('')
        //   .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        //   .split('')
        //   .reverse()
        //   .join('') || '')
      )
    }

    return number + ''
  }
}

export const logIfDev = (error: any) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log(error)
  }
}

export namespace PDFUtils {
  export const captureContainerAsImage = async (container: HTMLElement | HTMLDivElement) => {
    try {
      const canvas = await html2canvas(container)
      const imgData = canvas.toDataURL('image/png')

      const imgWidth = canvas.width
      const imgHeight = canvas.height

      return { imgData, ratio: imgWidth / imgHeight }
    } catch (error) {
      console.log('Error while taking screenshot:', error)
      return
    }
  }

  export const convertToPdf = (baseURL: string, ratio: number, fileName: string) => {
    const doc = new jsPDF()
    let width = doc.internal.pageSize.getWidth()
    let height = width / ratio

    if (doc.internal.pageSize.getHeight() < height) {
      height = doc.internal.pageSize.getHeight()
      width = height * ratio
    }

    doc.addImage(
      baseURL,
      'PNG',
      (doc.internal.pageSize.getWidth() - width) / 2 - 4.5,
      -4.5,
      width,
      height,
      '',
      'FAST',
    )
    doc.save(`${fileName}.pdf`)
  }
}
