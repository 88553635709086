import home from '../images/home.jpg'
import cycle1 from '../images/cycle1.svg'
import cycle2 from '../images/cycle2.svg'
import cycle3 from '../images/cycle3.svg'
import cycle4 from '../images/cycle4.svg'
import cycle5 from '../images/cycle5.svg'
import cycle6 from '../images/cycle6.svg'

import framework from '../images/framework.png'
import various from '../images/various.png'
import door from '../images/door.png'
import exterior from '../images/exterior.png'
import technical from '../images/technical.png'
import fence from '../images/fence.png'
import scenography from '../images/scenography.png'
import interior from '../images/interior.png'
import brick from '../images/brick.png'
import floor from '../images/floor.png'
import location from '../images/location.svg'
import secondaryLocation from '../images/secondaryLocation.svg'
import cluster from '../images/cluster.svg'
import materialIcon from '../images/icon_material.svg'
import handIcon from '../images/icon_hand.svg'
import projectsIcon from '../images/icon_projects.svg'

export const DEFAULT_RESOURCES: { [key: string]: any } = {
  framework,
  various,
  door,
  exterior,
  technical,
  fence,
  scenography,
  interior,
  brick,
  floor,
  location,
  secondaryLocation,
  cluster,
  home,
  cycle1,
  cycle2,
  cycle3,
  cycle4,
  cycle5,
  cycle6,
  materialIcon,
  handIcon,
  projectsIcon,
}
export type Resources = typeof DEFAULT_RESOURCES

export const CategoryName2Key = {
  'Charpente - Couverture': 'framework',
  Divers: 'various',
  'Serrurerie métallerie': 'door',
  'Aménagements Extérieurs - VRD': 'exterior',
  'Lots Techniques': 'technical',
  'Façade - Isolation - Menuiseries Extérieures - Fermetures': 'fence',
  Scénographie: 'scenography',
  'Aménagements et Menuiseries intérieures': 'interior',
  'Gros œuvre - Maçonnerie': 'brick',
  'Revêtements sols et murs': 'floor',
} as any
