import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import useRoute from '../../hooks/useRoute.hooks'
import { Mode, Route } from '../../models/commons.models'
import { Add as AddIcon, Download as DownloadIcon } from '@mui/icons-material'

import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import Tabs from '../../components/common/Tabs.common'
import Constants from '../../constants'
import useModal from '../../hooks/useModal.hooks'
import ModalFormCatalog from '../../components/catalog/ModalForm.catalog'
import { useObservable } from '@ngneat/react-rxjs'
import { sessionQuery, sessionService } from '../../store/session'
import { catalogsService } from '../../store/catalogs'
import { useCallback, useMemo, useState } from 'react'
import useOwnSnackbar from '../../hooks/useSnackbar.hooks'
import { Avatar, useMediaQuery, useTheme } from '@mui/material'
import { CatalogType } from '../../models/catalogs.models'
import LoaderOverlay from '../../components/layout/LoaderOverlay.layout'

const WorkspacePage = () => {
  useCheckRoute('Workspace', [Mode.front, Mode.admin, Mode.app])
  const { t } = useTranslation()
  const { getPath, goTo } = useRoute()
  const [modal, setModal] = useModal<CatalogType>()
  const [user] = useObservable(sessionQuery.user)
  const [loading, setLoading] = useState<boolean>(false)
  const isAdmin = useMemo(() => Constants.mode === Mode.admin, [])
  const showCerfaLabel = useMemo(() => sessionService.showCerfa(), [])
  const show = useOwnSnackbar()
  const theme = useTheme()
  const [useImperials] = useObservable(sessionQuery.useImperials)
  const downLg = useMediaQuery(theme.breakpoints.down('lg'))

  const resourceGroup =
    Constants.mode === Mode.app
      ? undefined
      : showCerfaLabel
      ? t(`catalogs:pages.workspace.resourcesDiagnostic`)
      : t(`catalogs:pages.workspace.resources`)
  const needGroup = t(`catalogs:pages.workspace.needs`)

  const exportCsv = useCallback(async () => {
    setLoading(true)
    try {
      await catalogsService.getCsv({})
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [show])

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.workspace')} />
      {loading && <LoaderOverlay />}

      <Tabs
        actions={[
          ...(Constants.mode === Mode.admin
            ? [
                {
                  label: downLg ? undefined : t('catalogs:actions.exportList'),
                  icon: undefined,
                  custom: downLg ? (
                    <Avatar sx={{ bgcolor: Constants.colors.primary }}>
                      <DownloadIcon />
                    </Avatar>
                  ) : undefined,
                  onClick: exportCsv,
                },
              ]
            : []),
          {
            label: downLg ? undefined : t('catalogs:components.list.add'),
            custom: downLg ? (
              <Avatar sx={{ bgcolor: Constants.colors.primary }}>
                <AddIcon />
              </Avatar>
            ) : undefined,
            items: [
              {
                label: showCerfaLabel
                  ? t('catalogs:pages.workspace.deconstructionDiagnostic')
                  : t('catalogs:type.deconstruction'),
                color: 'primary',
                group: resourceGroup,
                onClick: () => setModal(CatalogType.deconstruction),
              },
              {
                label: t('catalogs:type.storage'),
                color: 'primary',
                group: resourceGroup,
                onClick: () => setModal(CatalogType.storage),
              },
              ...(Constants.mode === Mode.app
                ? []
                : [
                    {
                      label: t('catalogs:type.construction'),
                      group: needGroup,
                      color: 'secondary' as const,
                      onClick: () => setModal(CatalogType.construction),
                    },
                    {
                      label: t('catalogs:type.needs'),
                      group: needGroup,
                      color: 'secondary' as const,
                      onClick: () => setModal(CatalogType.needs),
                    },
                  ]),
            ],
          },
        ]}
        items={[
          {
            label: showCerfaLabel
              ? t(`catalogs:pages.workspace.deconstructionDiagnostic`)
              : t(`global:routes.workspaceDeconstructions`),
            group: resourceGroup,
            path: getPath({
              route: Route.workspaceDeconstructions,
            }),
          },
          {
            label: t(`global:routes.workspaceStorages`),
            group: resourceGroup,
            path: getPath({
              route: Route.workspaceStorages,
            }),
          },
          ...(Constants.mode === Mode.admin
            ? []
            : [
                {
                  label: t(`global:routes.workspaceOrders`),
                  group: Constants.mode !== Mode.app ? resourceGroup : undefined,
                  path: getPath({
                    route: Route.workspaceOrders,
                  }),
                },
                {
                  label: t(`global:routes.workspaceReport`),
                  group: Constants.mode !== Mode.app ? resourceGroup : undefined,
                  path: getPath({
                    route: Route.workspaceReport,
                  }),
                },
              ]),
          ...(Constants.mode === Mode.app
            ? []
            : [
                {
                  label: t(`global:routes.workspaceConstructions`),
                  group: needGroup,
                  secondary: true,
                  path: getPath({
                    route: Route.workspaceConstructions,
                  }),
                },
                {
                  label: t(`global:routes.workspaceNeeds`),
                  group: needGroup,
                  secondary: true,
                  path: getPath({
                    route: Route.workspaceNeeds,
                  }),
                },
              ]),
        ]}
      />
      <Outlet context={{ useImperials }} />

      {modal && (
        <ModalFormCatalog
          catalog={{
            organization:
              Constants.mode !== Mode.admin ||
              user?.organization.platform === sessionService.getAdminPlatform()
                ? user?.organization
                : undefined,
            type: modal as CatalogType,
          }}
          isAdmin={isAdmin}
          useImperials={useImperials}
          // go to does not work is the modal is closed
          keepOpen
          onClose={() => setModal('')}
          onSubmit={catalogsService.createCatalog}
          submitLabel={t('global:actions.finish')}
          title={t('catalogs:actions.add.label')}
          onSuccess={(catalog) => {
            show(t('catalogs:actions.add.success'))
            goTo({ route: Route.workspaceCatalogMaterials, catalogId: catalog._id })
          }}
        />
      )}
    </>
  )
}
export default WorkspacePage
