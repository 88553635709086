import { MaterialType } from '../../../models/materials.models'
import { useOutletContext } from 'react-router-dom'
import PageMaterialList from '../../../components/page/MaterialList.page'
import { sessionService } from '../../../store/session'
import { useRef } from 'react'

const ListMaterialResourcePage = () => {
  const { useImperials } = useOutletContext<{
    useImperials: boolean
  }>()

  return (
    <PageMaterialList useImperials={useImperials} withTabs canUpdate type={MaterialType.resource} />
  )
}
export default ListMaterialResourcePage
