import { useEffect, useState, useCallback, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useObservable } from '@ngneat/react-rxjs'
import { Chip } from '@mui/material'

import StyledChip from '../../../components/common/Chip.common'
import Stack from '../../../components/common/Stack.common'
import CatalogHeader from '../../../components/catalog/Header.catalog'
import LoaderOverlay from '../../../components/layout/LoaderOverlay.layout'
import { catalogsQuery, catalogsService } from '../../../store/catalogs'
import { requestsService } from '../../../store/requests'
import { sessionQuery, sessionService } from '../../../store/session'

import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import { Mode, Route } from '../../../models/commons.models'

import {
  NetworkStatus,
  CatalogStatus,
  getCatalogStatusColor,
  getCatalogTypeColor,
  CatalogType,
  isResources,
  isNeeds,
} from '../../../models/catalogs.models'
import { Request, RequestStatus } from '../../../models/requests.models'
import { useTranslation } from 'react-i18next'
import { AdminHeaderTitle } from '../../../components/layout/AdminHeader.layout'
import Constants from '../../../constants'
import ModalPemdMaterial from '../../../components/material/ModalPemd.material'
import { materialsQuery } from '../../../store/materials'

const WorkspaceCatalog = () => {
  useCheckRoute('WorkspaceCatalog', [Mode.front, Mode.admin, Mode.app])
  const { goTo, isAtRoute, getPath } = useRoute()
  const { catalogId } = useParams()
  const { t } = useTranslation()
  const show = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [user] = useObservable(sessionQuery.user)
  const [useImperials] = useObservable(sessionQuery.useImperials)
  const [catalog] = useObservable(catalogsQuery.catalog)
  const [catalogLoading] = useObservable(catalogsQuery.detailLoading)

  const showCerfa = useMemo(
    () => sessionService.showCerfa() && catalog?.type === CatalogType.deconstruction,
    [catalog?.type],
  )

  const [materialsLoading] = useObservable(materialsQuery.loading)
  const [materials] = useObservable(materialsQuery.materials)

  const [isPublic] = useObservable(catalogsQuery.isPublic)
  const [request, setRequest] = useState<Request>()

  const showStatus = useMemo(() => {
    return !catalog?.type
      ? false
      : sessionService.showStatus({ type: catalog?.type, isPublic: false })
  }, [catalog?.type])

  useEffect(() => {
    if (!catalogId || catalogId?.includes('local.')) {
      return
    }
    const getRequest = async () => {
      setLoading(true)
      try {
        const { data } = await requestsService.getRequests({
          sent: Constants.mode !== Mode.admin,
          status: [RequestStatus.pending],
          catalog: catalogId,
        })
        if (data.length === 1) {
          setRequest(data[0])
        }
      } catch (err: any) {}
      setLoading(false)
    }
    getRequest()
  }, [catalogId])

  useEffect(() => {
    if (!catalog) {
      return
    } else if (
      (catalog.type !== CatalogType.deconstruction && isAtRoute(Route.workspaceCatalogPlans)) ||
      (!isResources(catalog.type) && isAtRoute(Route.workspaceCatalogOrders)) ||
      (!isNeeds(catalog.type) && isAtRoute(Route.workspaceCatalogMatching))
    ) {
      goTo({ route: Route.workspaceCatalog, catalogId: catalog._id })
    }
  }, [goTo, catalog, isAtRoute])
  useEffect(() => {
    const getCatalog = async () => {
      try {
        await catalogsService.initDetail(catalogId as string)
      } catch (err: any) {
        show(err)
        goTo({ route: Route.workspace })
      }
    }
    if (!loading && catalogId && (!catalog || catalog._id !== catalogId || isPublic)) {
      getCatalog()
    }
  }, [goTo, show, catalogId, catalog, loading, isPublic])

  const isAdmin = useMemo(() => Constants.mode === Mode.admin, [])
  const isLocal = useMemo(() => Constants.getIsLocal(), [])
  const isSync = useMemo(
    () =>
      !isLocal ||
      (catalog?.networkStatus === NetworkStatus.sync &&
        catalog.lastSyncAt &&
        (!catalog?.localUpdatedAt || catalog?.lastSyncAt >= catalog?.localUpdatedAt)),
    [catalog, isLocal],
  )
  const canUpdate = useMemo(
    () => catalog && (catalog.status !== CatalogStatus.pending || isAdmin),
    [catalog, isAdmin],
  )

  const [pemdModal, setPemdModal] = useState<'pem' | 'd' | undefined>(undefined)
  const checkSync = useCallback(
    async (callback: () => Promise<any>) => {
      if (!isSync) {
        throw new Error('NEED_SYNC')
      } else {
        return await callback()
      }
    },
    [isSync],
  )

  if (catalogLoading || !catalog?._id || catalog._id !== catalogId) return <LoaderOverlay />

  const onAction = async (format: 'csv' | 'xls' | 'pdf' | 'csv-pem' | 'csv-d') => {
    setLoading(true)
    try {
      await checkSync(async () => {
        await catalogsService.downloadDiagnostic(catalog, format)
      })
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }

  return (
    <>
      {loading && <LoaderOverlay />}
      <AdminHeaderTitle
        title={catalog.name}
        canGoBack={
          catalog.type === CatalogType.construction
            ? getPath({ route: Route.workspaceConstructions })
            : catalog.type === CatalogType.needs
            ? getPath({ route: Route.workspaceNeeds })
            : catalog.type === CatalogType.storage
            ? getPath({ route: Route.workspaceStorages })
            : getPath({ route: Route.workspaceDeconstructions })
        }>
        <>
          {showStatus &&
            [CatalogStatus.draft, CatalogStatus.pending, CatalogStatus.accepted].includes(
              catalog.status,
            ) && (
              <StyledChip
                color={getCatalogStatusColor(catalog.status)}
                label={t(`catalogs:status.${catalog.status}`)}
              />
            )}
          <Chip
            color={getCatalogTypeColor(catalog.type)}
            label={t(`catalogs:type.${catalog.type}`)}
          />
        </>
      </AdminHeaderTitle>

      <CatalogHeader
        catalog={catalog}
        isAdmin={isAdmin}
        showCerfa={showCerfa}
        onAction={(action: string) => {
          switch (action) {
            case 'goBack':
              if (catalog.type === CatalogType.construction) {
                goTo({ route: Route.workspaceConstructions })
              } else if (catalog.type === CatalogType.needs) {
                goTo({ route: Route.workspaceNeeds })
              } else if (catalog.type === CatalogType.storage) {
                goTo({ route: Route.workspaceStorages })
              } else {
                goTo({ route: Route.workspaceDeconstructions })
              }
              break
            case 'csv':
            case 'xls':
            case 'pdf':
              onAction(action)
              break
            case 'csv-pem':
            case 'csv-d':
              setPemdModal(action.replace('csv-', '') as 'pem' | 'd')
              break
            default:
          }
        }}
      />
      <Stack height="100%" overflow="hidden" flexGrow={1}>
        <Outlet
          context={{
            user,
            catalog,
            isSync,
            canUpdate,
            checkSync,
            isAdmin,
            isLocal,
            request,
            useImperials,
          }}
        />

        {pemdModal && !materialsLoading && (
          <ModalPemdMaterial
            materials={materials}
            useImperials={useImperials}
            type={pemdModal}
            onSubmit={onAction.bind(null, `csv-${pemdModal}`)}
            onClose={setPemdModal.bind(null, undefined)}
          />
        )}
      </Stack>
    </>
  )
}
export default WorkspaceCatalog
