import DetailsComponent from '../common/Details.common'
import {
  Catalog,
  BuildingType,
  InterventionType,
  CatalogType,
  isResources,
} from '../../models/catalogs.models'
import { Actions, DetailItem } from '../../models/props.models'
import { Location } from '../../models/commons.models'
import { Organization } from '../../models/organizations.models'
import { useTranslation } from 'react-i18next'
import { StringUtils } from '../../utils/commons.utils'
type InformationCatalogProps = {
  title: string
  catalog: Catalog
  actions?: Actions
  isPublic?: boolean
  isAdmin?: boolean
  additionals?: DetailItem[]
  useImperials: boolean
}
const InformationCatalog: React.FC<InformationCatalogProps> = ({
  title,
  catalog,
  actions,
  isPublic,
  isAdmin,
  useImperials,
  additionals,
}) => {
  const { t } = useTranslation()

  return (
    <DetailsComponent
      title={title}
      value={catalog}
      actions={actions}
      items={[
        ...(isPublic
          ? []
          : [
              { label: t('catalogs:attributes.name'), key: 'name' },
              {
                label: t('catalogs:attributes.location'),
                key: 'location',
                formatValue: (location: Location) => location.fullAddress?.replace(', ', '\n'),
              },
            ]),
        {
          label: t('catalogs:attributes.buildingType'),
          key: 'buildingType',
          formatValue: (buildingType: BuildingType) =>
            buildingType ? t(`catalogs:buildingType.${buildingType}`) : '',
        },
        {
          label: t('catalogs:attributes.buildingSurface'),
          key: 'buildingSurface',
          formatValue: (buildingSurface: number) => {
            if (!buildingSurface) {
              return ''
            }
            const unit = useImperials ? t('materials:unitSymbol.yd2') : t('materials:unitSymbol.m2')
            const factor = useImperials ? 0.9144 * 0.9144 : 1
            return `${StringUtils.formatNumber(buildingSurface / factor)}${unit}`
          },
        },
        ...(isPublic
          ? []
          : [
              {
                label: t('catalogs:attributes.interventionTypes'),
                key: 'interventionTypes',
                formatValue: (interventionTypes: InterventionType[]) =>
                  interventionTypes
                    .map((interventionType) => t(`catalogs:interventionType.${interventionType}`))
                    .join('\n'),
              },
              ...(catalog.createdBy && catalog.organization?._id !== catalog.createdBy?._id
                ? [
                    {
                      label: t('catalogs:attributes.createdBy'),
                      key: 'createdBy',
                      formatValue: (createdBy: Organization) => createdBy?.name,
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: t('catalogs:attributes.organization'),
                      key: 'organization',
                      formatValue: (organization: Organization) => organization.name,
                    },
                  ]
                : []),
              {
                label: t('catalogs:attributes.projectOwner'),
                key: 'projectOwner',
                formatValue: (projectOwner: Organization) =>
                  projectOwner?.name || catalog?.customProjectOwner || '',
              },
              {
                label: t('catalogs:attributes.designer'),
                key: 'designer',
                formatValue: (designer: string) => designer || '',
              },
              {
                label: t('catalogs:attributes.amoReuse'),
                key: 'amoReuse',
                formatValue: (amoReuse: string) => amoReuse || '',
              },
            ]),
        ...(catalog.type === CatalogType.deconstruction
          ? [
              {
                label: t('catalogs:attributes.constructionDate'),
                key: 'constructionDate',
                formatValue: (constructionDate: string) =>
                  constructionDate
                    ? t('global:format.date', { date: new Date(constructionDate) })
                    : '',
              },
              {
                label: t('catalogs:attributes.originalActivity'),
                key: 'originalActivity',
                formatValue: (originalActivity: string) => originalActivity || '',
              },
            ]
          : []),
        ...(isPublic
          ? []
          : [
              {
                label: t('catalogs:attributes.amountOfWork'),
                key: 'amountOfWork',
                formatValue: (amountOfWork: number) =>
                  amountOfWork
                    ? `${StringUtils.formatNumber(amountOfWork)}${t(
                        `global:currency.${catalog?.currency}`,
                      )}`
                    : '-',
              },
              {
                label: t('catalogs:attributes.hoursDedicatedToReuse'),
                key: 'hoursDedicatedToReuse',
                formatValue: (hoursDedicatedToReuse: number) =>
                  hoursDedicatedToReuse
                    ? `${StringUtils.formatNumber(hoursDedicatedToReuse)}`
                    : '-',
              },
              ...(catalog.type === CatalogType.construction
                ? [
                    {
                      label: t('catalogs:attributes.percentageOfNeed'),
                      key: 'percentageOfNeed',
                      formatValue: (percentageOfNeed: number) =>
                        percentageOfNeed !== undefined
                          ? t('global:format.percent', { value: percentageOfNeed })
                          : '',
                    },
                    {
                      label: t('catalogs:attributes.secondHandAmount'),
                      key: 'secondHandAmount',
                      formatValue: (_: any) =>
                        catalog.percentageOfNeed !== undefined && catalog.amountOfWork !== undefined
                          ? `${StringUtils.formatNumber(
                              (catalog.amountOfWork * catalog.percentageOfNeed) / 100,
                            )}${t(`global:currency.${catalog?.currency}`)}`
                          : '',
                    },
                  ]
                : []),
            ]),
        {
          label: t('catalogs:attributes.operationContext'),
          column: !!catalog.operationContext,
          key: 'operationContext',
          formatValue: (operationContext: string) => operationContext || '',
        },
        ...(isResources(catalog.type)
          ? [
              {
                key: 'retrieval',
                label: t('catalogs:attributes.retrieval'),
                isTitle: true,
              },
              {
                label: t('catalogs:attributes.retrievalLocation'),
                key: 'retrievalLocation',
                formatValue: (_: any) =>
                  catalog.retrieval?.location?.fullAddress?.replace(', ', '\n'),
              },
              ...(catalog.type === CatalogType.deconstruction
                ? [
                    {
                      label: t(`catalogs:attributes.deconstructionStartDate`),
                      key: 'startDate',
                      formatValue: (_: any) =>
                        catalog.retrieval.startDate
                          ? t('global:format.date', {
                              date: new Date(catalog.retrieval.startDate),
                            })
                          : '',
                    },
                    {
                      label: t(`catalogs:attributes.deconstructionEndDate`),
                      key: 'endDate',
                      formatValue: (_: any) =>
                        catalog?.retrieval.endDate
                          ? t('global:format.date', {
                              date: new Date(catalog?.retrieval.endDate),
                            })
                          : '',
                    },
                  ]
                : []),
              {
                label: t('catalogs:attributes.retrievalModality'),
                key: 'retrievalModality',
                formatValue: (_: any) =>
                  catalog?.retrieval?.retrievalModality
                    ? t(`global:retrievalModality.${catalog.retrieval?.retrievalModality}`)
                    : '',
              },
              {
                label: t('catalogs:attributes.retrievalInformation'),
                column: !!catalog.retrievalInformation,
                key: 'retrievalInformation',
                formatValue: (retrievalInformation: string) => retrievalInformation || '',
              },
            ]
          : catalog.type === CatalogType.construction
          ? [
              {
                label: t(`catalogs:attributes.constructionStartDate`),
                key: 'startDate',
                formatValue: (_: any) =>
                  catalog.retrieval.startDate
                    ? t('global:format.date', {
                        date: new Date(catalog.retrieval.startDate),
                      })
                    : undefined,
              },
              {
                label: t(`catalogs:attributes.constructionEndDate`),
                key: 'endDate',
                formatValue: (_: any) =>
                  catalog.retrieval.endDate
                    ? t('global:format.date', {
                        date: new Date(catalog.retrieval.endDate),
                      })
                    : undefined,
              },
            ]
          : []),

        ...(additionals || []),
      ]}
    />
  )
}
export default InformationCatalog
